<template>
  <base-material-card
    color="success"
    icon="mdi-clipboard-text"
    inline
    title="发布文章"
    class="px-5 py-3 mx-5 mt-5"
  >
    <v-form v-model="valid">
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="title"
            label="文章标题"
            outlined
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="categoryId"
            :items="columns"
            label="所属栏目"
            item-text="name"
            item-value="id"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="summary"
            name="input-7-1"
            label="文章简介"
            value=""
            hint="字数不能超过120字"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
        >
          <div id="editor" />
        </v-col>
        <v-col cols="6">
          <v-file-input
            v-model="featureImage"
            multiple
            label="缩略图"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-btn
            v-if="operation === 'add'"
            class="mt-5"
            color="success"
            @click="addArticle"
          >
            发布文章
          </v-btn>
          <v-btn
            v-if="operation === 'edit'"
            class="mt-5"
            color="success"
            @click="editArticle"
          >
            修改文章
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
  import WangEditor from 'wangeditor'
  export default {
    data () {
      return {
        operation: 'add',
        id: null,
        editor: '',
        editorobj: null,
        token: '',
        columns: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        categoryId: null,
        title: null,
        summary: null,
        featureImage: null,
        content: null,
        valid: false,
      }
    },
    created () {
      this.token = localStorage.token
      this.getColumn()
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.operation = 'edit'
        this.getArticle()
      }
    },
    mounted () {
      const editor = new WangEditor('#editor')
      console.log(editor)
      // editor.config.height = 800
      editor.create()
      editor.config.onchange = (newHtml) => {
        this.editorData = newHtml
      }
      // 自定义文件上传方法
      editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        const upLoadImg = this.upLoadImg(resultFiles)
        upLoadImg.then(res => {
          insertImgFn(res.data.data.url)
        })
      }
      this.editor = editor
    },
    methods: {
      getArticle () {
        this.$axios.get(`/posts/${this.id}`)
          .then(res => {
            const resData = res.data.data
            this.title = resData.title
            this.summary = resData.summary
            this.categoryId = resData.categoryId
            this.featureImage = resData.featureImage
            this.editor.txt.html(resData.content)
          })
      },
      getColumn () {
        this.$axios.get('/categories/article')
          .then(res => {
            this.columns = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      },
      getEditorData () {
        const data = this.editor.txt.html()
        alert(data)
      },
      async upLoadImg (resultFiles) {
        const file = resultFiles[0]
        const param = new FormData() // 创建form对象
        param.append('file', file) // 通过append向form对象添加数据
        return await this.$axios.post('https://zdwz.api.sdcom.gov.cn/api/files', param, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: this.token,
          },
        })
      },
      addArticle () {
        this.$axios.post('/posts', {
          title: this.title,
          summary: this.summary,
          categoryId: this.categoryId,
          featureImage: this.featureImage,
          content: this.editor.txt.html(),
        })
          .then(res => {
            this.$router.push({
              path: '/admin/article',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      editArticle () {
        this.$axios.put(`/posts/${this.id}`, {
          title: this.title,
          summary: this.summary,
          categoryId: this.categoryId,
          featureImage: this.featureImage,
          content: this.editor.txt.html(),
        })
          .then(res => {
            this.$router.push({
              path: '/admin/article',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
